@import '../theme/vars.less';

.ant-row + .ant-row {
	margin-top: 2px;
}

.ant-row label {
	line-height: 24px;
}

label {
	font-weight: 500;
}

.ant-collapse-content > .ant-collapse-content-box {
	padding: 10px;
}

.ant-row + .ant-collapse {
	margin-top: 10px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	padding: 6px 0 6px 32px;
	font-weight: 400;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
	left: 8px;
}

.form-label-optional {
	color: #989898;
}

.form-error {
	> input,
	textarea,
	select,
	.ant-picker,
	.ant-select-selector,
	.dictionary-picker,
	.ant-input-password,
	.ant-input-number {
		border: 1px solid red !important;
	}

	.ant-picker {
		.ant-picker-input {
			&,
			& input {
				border: none !important;
			}
		}
	}
}

.form-date-error {
	border: 1px solid red;
}

.form-error .ant-select-selection {
	border: 1px solid red;
}

.form-error + Button {
	margin-top: 10px;
}

.form-table-error {
	border: 1px solid red;
}

.ant-list-sm .ant-list-item {
	padding-top: 1px;
	padding-bottom: 1px;
}

.ant-list-pagination {
	margin-top: 6px;
}

.ant-select-selection__clear {
	color: rgba(255, 0, 0, 0.5);
}

.form-panel-error {
	> .ant-collapse-header {
		background-color: rgba(255, 0, 0, 0.3);
	}

	border-color: rgba(255, 0, 0, 0.3);
}

.form-error-label {
	color: #ff0000;
}

.ant-select {
	min-width: 100%;
	width: 100%;
}

.anticon:focus {
	outline-color: @select-item-selected-option-color;
}

.btn-transparent {
	border: none !important;
	background-color: transparent !important;
	text-align: left;
}
.menu-wcag {
	button {
		padding-left: 0;
	}
	.ant-btn {
		&:focus,
		&:focus-within {
			border: none !important;
		}
	}
}
@searchBarRadius: 10px;

.searchDownshiftList {
	border-bottom-left-radius: @searchBarRadius;
	border-bottom-right-radius: @searchBarRadius;
	border: 1px solid @primary-color;
	background-color: @drop-down-list;
	z-index: 10;
	width: 100%;
	min-width: 100%;
}

.searchDownshift {
	border-radius: @searchBarRadius;
	border: 1px solid @primary-color;
	min-width: 100%;
	width: 100%;
}

.searchBar {
	width: 100%;
	min-width: 100%;
	box-shadow: 5px 3px 15px @primary-color;
	border-radius: @searchBarRadius;
	max-height: unset;
	min-height: 52px;
	border: 1px solid @primary-color;
	padding: 1px;

	&:focus-within {
		padding: 0px;
	}

	.ant-input-wrapper {
		min-width: inherit;
		border-radius: inherit;
		min-height: inherit;

		.ant-input-suffix {
			font-size: 1.5em;
		}

		.ant-input {
			padding: 0 12px;

			&,
			&:focus,
			&:focus-within {
				min-height: inherit !important;
				border: 0 !important;
			}

			textarea {
				background-color: @input-bg;
			}
		}

		input,
		textarea {
			font-size: 1.4em;
			padding: 0;
		}

		.ant-mentions {
			padding: 0;
			border-radius: inherit;

			&,
			&:focus {
				box-shadow: none !important;
			}
		}

		textarea {
			min-height: inherit !important;
			height: inherit !important;
			max-height: inherit !important;

			line-height: 52px !important;
			padding: 0 12px;
		}

		.ant-input-group-addon {
			.anticon-search {
				font-size: 1.5em;
				color: @primary-color;
			}

			border-radius: @searchBarRadius;
			background: transparent;
			.ant-btn {
				border: 0 !important;
				border-radius: 50%;
			}
		}
	}
}

div.ant-tabs-nav-list div.ant-tabs-tab {
	border-bottom: 2px solid transparent !important;
}

.ant-tabs-nav-list .ant-tabs-tab:focus-within,
.ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active:focus-within {
	border-bottom: 2px solid @select-item-selected-option-color !important;
}

.ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active:focus-within {
	z-index: 3 !important;
}

.ant-tabs-nav-list .ant-tabs-tab {
	border: 2px !important;
}

.border-red-treeselect {
	border: solid 1px red;
	border-radius: 4px;
}

.border-red-treeselect:hover {
	border: none;
}

.ant-select-item-option-content {
	white-space: pre-line;
}
.range-picker-error {
	border: 1px solid red !important;
	border-radius: 6px !important;
}
.form-error span.ant-checkbox {
	border: 1px solid red !important;
}
.remove-steps .ant-input-number-handler-wrap {
	display: none;
}

.readonly-styled-pattern {
	background-color: @item-hover-bg;
	border-color: @border-color-base;
	color: @text-color;
}

.readonly-styled-focused-pattern {
	outline: 0;
	border-color: @border-color-base;
	box-shadow: inherit;
}

.ant-input:read-only {
	&:extend(.readonly-styled-pattern);
}

.ant-input-number-readonly {
	&:extend(.readonly-styled-pattern);
	cursor: not-allowed;

	&:hover {
		border-color: @border-color-base;
	}

	.ant-input-number-handler-wrap {
		display: none;
	}
}

.ant-picker {
	&-readonly {
		&:extend(.readonly-styled-pattern);

		& input[disabled] {
			color: @text-color !important;
		}
	}
}

.ant-select-selector:read-only {
	&:extend(.ant-select-disabled);
}

.ant-select input,
.ant-select-selector,
.ant-input,
.ant-input-number-input,
.ant-picker-input input {
	color: @text-color !important;
}

.ant-input-number-handler-wrap {
	display: none;
}

.minimal-scroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.minimal-scroll::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

.minimal-scroll::-webkit-scrollbar-thumb {
	background-color: @layout-footer-background;
	border-radius: 8px;
}

#pop-confirm {
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
	padding: 10px;
	z-index: 1060 !important;
}

.btn--focusable:focus {
	border: 1px solid @select-item-selected-option-color;
	border-radius: 4px;
}

.ant-table-cell,
.ant-descriptions-item {
	&:focus {
		outline-color: @primary-color;
	}
}

.column-title--accessible:focus {
	outline: none;
}

.tab--invalid {
	border: 1px solid red !important;
	border-radius: 4px !important;
}

.ant-select-disabled.dictionary-picker.ant-select-multiple
	.ant-select-selection-item {
	border: 1px solid @border-color-base !important;
	background-color: @input-bg !important;
	color: @text-color !important;
	font-size: 12px;
}

.ant-select-disabled button.ant-input.ant-select-selector:focus {
	border: 1px solid @border-color-base !important;
	box-shadow: none !important;
}

.commentType label {
	display: block !important;
}

.ant-switch {
	&:focus {
		box-shadow: 0 0 0 2px @switch-focus !important;
	}

	.ant-switch-handle::before {
		background-color: @revert-icon-color !important;
	}
}

.save-changes-no-answer-button{
	background: @modal-close-color;
	border: 1px solid @border-color-base !important;
	color: @text-color;
}


