@import '../../../styles/theme/vars.less';
@import '../../../styles/theme/index.less';

.menu {
	display: grid;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	grid-template-columns: 2vw 75px;
}

.tableHeader {
	margin: 15px 0px 0px 2vw;
	border-radius: 10px;
	background-color: @secondary-color;
	color: white;
	font-size: 16px;
	font-weight: 500;
	display: inline-block;
	p {
		padding: 8px;
		margin: auto;
	}
}

.tableContainer {
	.shadow-box;
	margin: 15px 2vw;
	padding: 0px 2px;

	.table {
		padding: 0px 2px;
		:global {
			.masked-table;
		}
	}
}
