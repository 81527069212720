@import '../../../styles/theme/index.less';

.departments {
	margin-left: 22vw;

	.description {
		:global {
			.shadow-box;

			.masked-table;
		}

		padding: 10px;
		margin: 10px 0px 10px 0px;
		.fieldbox {
			grid-template-columns: 160px 160px;
			margin: 5px 0px;
			&.short {
				grid-template-columns: 70px 160px;
			}
		}
	}

	.createButton {
		float: right;
		margin: 5px 0px 5px auto;
	}

	.filters {
		.item {
			display: grid;
			grid-template-columns: 90px 345px;
		}
	}
}
