@import '../../../styles/theme/index.less';

.orders {
	.menu {
		display: grid;
		justify-content: flex-start;
		align-content: center;
		align-items: center;
		grid-template-columns: 2vw 75px calc(84vw - 75px);

		.searchBar {
			width: 350px;
			min-width: 350px;
			margin: auto 0px auto auto;
		}

		.filters {
			margin-left: auto;
			display: grid;
			.item {
				float: right;
				margin: 2px 0px 2px 0px;
				display: grid;
				grid-template-columns: 115px 250px 30px;
				gap: 5px;
			}
		}
	}

	.tableContainer {
		.shadow-box;
		margin: 15px 2vw;
		padding: 0px 2px;

		.table {
			padding: 0px 2px;
			:global {
				.masked-table;
			}
		}
	}
}
