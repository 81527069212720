.filters {
    display: inline-grid;
    grid-template-columns: 10% 430px 160px;
    gap: 15px;
    .item {
        display: grid;

        &.voivodeship {
            grid-template-columns: 90px 345px;
        }

        &.settlementPeriod {
            grid-template-columns: 125px 170px 35px;
        }
    }
}