@import '../../../styles/theme/vars.less';
@import '../../../styles/theme/index.less';
.priceLists {
	.menu {
		width: 50vw;
		display: grid;
		grid-template-columns: 30% 1000px 260px 320px;

		.filters {
			display: grid;
			grid-template-columns: 40% 60%;
			margin-bottom: 10px;

			.radioFilters {
				margin-left: auto;
			}
		}

		.importButton {
			margin: calc(50% + 50px) 50px 50px 50px;
		}

		.table {
			.shadow-box();

			:global {
				.masked-table();
			}

			padding: 15px;
			min-height: 220px;

			.markedRow {
				background: @secondary-color;
				color: white;

				&:hover {
					background: @secondary-color;

					td {
						background: @secondary-color;
					}
				}

				:global {
					td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
						background: @secondary-color;
					}
				}
			}
		}

		h2 {
			margin-bottom: 0px;
		}
	}

	.productPrices {
		width: 50vw;
		display: grid;
		grid-template-columns: 30% calc(100vw - 50%);

		h2 {
			margin: 5px 0px;
			display: inline-block;
		}

		.searchBar {
			width: 300px;
			min-width: 300px;
			float: right;
			display: inline;
			margin-top: 5px;
		}

		.table {
			.shadow-box();
			:global {
				.masked-table();
			}

			padding: 15px;
		}
	}
}
