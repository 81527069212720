@import '../../../styles/theme/vars.less';

.essity-table {
	.ant-table-filter-column-title {
		padding: 2px !important;
	}

	.ant-table-cell {
		padding: 2px !important;
		font-weight: 400;
	}

	.ant-table-filter-column {
		margin: -8px 0px !important;
	}

	.ant-table-column-sorters {
		padding: 5px 0px !important;
	}

	.ant-table-filter-trigger-container {
		right: 15px;
		color: black;
	}

	thead.ant-table-thead {
		height: 33px;
	}

	th.ant-table-cell {
		border-right: 1px solid fade(#7f7f7f, 22%);
		font-weight: 500;
	}
}

.draggableTable {
	tr {
		&.drop-over-downward td {
			border-bottom: 2px dashed primary-color !important;
		}
		&.drop-over-upward td {
			border-top: 2px dashed primary-color !important;
		}
	}
}

.ant-table-row {
	.actions-toolbox {
		opacity: 0;
	}

	&:hover .actions-toolbox {
		opacity: 1 !important;
	}
	.actions-table-column:focus-within .actions-toolbox {
		opacity: 1 !important;
	}
}

.actions-table-column {
	position: absolute !important;
	right: 10px;
	padding-left: 0 !important;
	padding-right: 0 !important;
	empty-cells: show;
	overflow: visible;
	border: none !important;
	border-radius: 5px;
	margin-top: -12px;

	.actions-toolbox {
		background-color: @actions-table-column-bg !important;
	}
}

th.actions-table-column,
td.display-none,
th.display-none {
	display: none;
}

.sidebar {
	&-layout {
		padding: 24px 0;
	}

	&-content {
		padding: 0 0 0 12px;
		margin-top: -34px !important;
	}
}

.exclamation-mark {
	color: red;
	font-size: x-large;
	width: 20;
	vertical-align: sub;
	margin-left: -8px;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	color: @table-header-color;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
	color: @table-header-filter-active-color;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: @primary-color;
	font-weight: 500;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: @menu-item-selected-bg;
	border: 1px solid @menu-item-selected-border-color !important;
}
