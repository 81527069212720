@import '../../../styles/theme/index.less';

.sendingPayments {
	.menu {
		margin-left: 2vw;

		.modalSummary {
			display: grid;
			grid-template-columns: 150px 50px 50px 50px;
			gap: 15px;
			background-color: red;
		}
	}

	.tableContainer {
		.shadow-box;
		margin: 15px 2vw;
		padding: 0px 2px;

		.table {
			padding: 0px 2px;
			:global {
				.masked-table;
			}
		}
	}
}
