@import '../../../styles/theme/index.less';
@import '../../../styles/theme/vars.less';

.note {
	display: grid;
	grid-template-columns: calc(49vw - 10px) 10px calc(49vw - 10px);

	.details {
		padding-left: 8vw;

		.form {
			.shadow-box;
			height: 85vh;
			padding: 25px;
			width: 100%;

			.datePicker,
			.inputField {
				width: 260px !important;
			}

			.shortDatePicker {
				width: 125px;
			}

			.actions {
				margin: 10px 0px;

				.save {
					width: 250px;

					.secondaryButton;
				}
			}
		}
	}

	.preview {
		margin-left: 15px;
		.viewer {
			.shadow-box;
			padding-left: 8vw;
			padding: 25px;
			height: 85vh;

			iframe {
				height: calc(85vh - 35px);
			}
		}
	}

	.divider {
		height: 85vh;
	}
}
