@import '../../styles/theme/index.less';

.table-with-title {
	h2 {
		margin: auto 0px auto 8vw;
		.header-text();
	}

	.table-component {
		margin-left: 8vw;
		margin-right: 8vw;
	}
}
