@import '../../../styles/theme/index.less';

.menu {
	margin-left: 2vw;

	h2 {
		margin: auto;
	}
}

.tableContainer {
	.shadow-box;
	margin: 15px 2vw;
	padding: 0px 2px;

	.table {
		padding: 0px 2px;
		:global {
			.masked-table;

			td.ant-table-cell {
				background-color: white;
			}
		}

		.summaryRecord {
			font-size: 17px;
			color: #f40785;
			font-weight: bold;
			margin-bottom: 2px;
		}

		.summary {
			color: #f40785;
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 2px;
		}
	}
}
.confirmationSummary{
	color: @secondary-color;
	font-weight: bold;
}
