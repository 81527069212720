@import '../../styles/theme/vars.less';

.account-menu-vertical.ant-menu-sub {
	.ant-menu-item.sub-menu-item,
	.ant-menu-item.ant-menu-submenu {
		margin: 0px;
		height: 40px;
		line-height: 40px;
		width: 100%;
		border: none;
		background-color: transparent;
		text-align: left;
	}
}
