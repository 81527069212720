@import '../styles/theme/vars.less';

.top-bar {
	width: 100%;
	position: fixed;
	text-align: center;
	height: 65px;
	border-radius: 0;
	z-index: 10;

	margin-bottom: 32px;

	.logo {
		float: left;
	}
}
.account-container {
	align-items: center !important;
	display: flex !important;
	justify-content: end !important;
}
.account {
	padding-right: 7px;
	color: #fff;

	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	& > *:not(:last-child) {
		margin-right: 10px !important;
	}

	.ant-avatar {
		margin-left: 5px;
		margin-bottom: 5px;
		vertical-align: middle;
		background-color: @primary-color;
		color: @avatar-color;
	}

	.ant-btn-circle.ant-btn-lg {
		border-radius: 50%;
		background-color: @primary-color;
		color: @avatar-color;
	}
}

.ant-menu-horizontal {
	border-bottom: 0 !important;
	background-color: transparent;

	.ant-menu-item .anticon,
	.ant-menu-submenu .anticon {
		font-size: 20px;
		vertical-align: text-top;
	}
}

.account .ant-avatar {
	font-size: 18px;
}

.page-title button + a,
.page-title a + button {
	margin-left: 4px;
}

.theme-radio-group {
	label {
		opacity: 0.7;
	}
	.ant-radio-button-wrapper-checked {
		opacity: 1;
	}
}

.material-icon-fix {
	font-size: 25px !important;
}

.material-icon-small {
	font-size: 17px !important;
	vertical-align: sub;
}
.offset0 {
	margin: 0 !important;
	padding: 0 !important;
}

.infinite-loading {
	position: absolute;
	bottom: -40px;
	left: 50%;
}

.ant-tag.wcag {
	padding: 0px;
	border-color: @label-color-border;

	.ant-btn {
		border: none;
		font-size: 9pt;
	}
}
.ant-tag.closeable-tag {
	padding-right: 0px;
	background-color: @label-color-bg;
	color: @label-color-fg;

	.ant-tag-close-icon {
		.ant-btn {
			border: none;
			padding: 0px 5px;
			background-color: @label-color-bg;
			color: @label-color-fg;
		}
	}
}

.float-right {
	float: right;
}

.ant-modal-header {
	.ant-typography {
		color: @modal-heading-color;
	}
}

.ant-back-top {
	bottom: 20px;
	right: calc(50vw - 20px);
	width: 40px;
	height: 40px;

	.ant-back-top-content {
		background-color: @ant-back-top-content-bg;

		&:hover {
			background-color: @ant-back-top-content-hover-bg;
			color: @sidebar-empty-text;
		}
	}
}