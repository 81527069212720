@import '../../styles/theme/vars.less';

.ant-menu {
	a.ant-menu-item,
	button.ant-menu-item {
		color: @menu-item-color;
		font-weight: 500;
	}

	.ant-menu-submenu-title {
		width: 100%;
		text-align: left;
		font-weight: 500;
	}

	.sub-menu-item {
		&:hover,
		&:focus {
			outline: 0 !important;
			background-color: @skeleton-color !important;
			border: none !important;
		}
	}
}

button.ant-menu-submenu {
	border-color: @select-item-selected-option-color;
	margin-top: 0px;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	color: @primary-color;
	background-color: transparent;
	padding: 0px;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
	top: 0px;
}

.profi-menu-vertical.ant-menu-sub {
	.ant-menu-item.sub-menu-item,
	.ant-menu-item.ant-menu-submenu {
		margin: 0px;
		height: 40px;
		line-height: 40px;
		width: 100%;
		border: none !important;
	}
}

.top-menu-bar {
	.ant-menu-horizontal .ant-menu-item,
	.ant-menu-horizontal .ant-menu-submenu {
		margin-top: 0 !important;
	}

	.ant-menu-item {
		&:focus,
		&:focus-within {
			border-bottom: none !important;
		}
	}
	.ant-menu-submenu {
		&:focus,
		&:focus-within {
			border-bottom: none !important;
			outline: 0 !important;
		}
	}

	.sub-menu-item {
		&:focus,
		&:focus-within,
		&:hover {
			border: none !important;
		}
	}

	.ant-menu-item.main-menu,
	.ant-menu-submenu.main-menu {
		margin: 0 !important;
		margin-left: 20px !important;
		margin-right: 20px !important;
		height: 60px;
	}

	.ant-menu-submenu-title {
		.ant-menu-submenu-arrow {
			right: 4px;
		}
	}
}

.sidebar-layout {
	.ant-menu {
		li.ant-menu-item:focus-within {
			background-color: @skeleton-color !important;
		}
	}

	&-submenu-item {
		font-weight: 500;
		padding: 0;
	}
}
