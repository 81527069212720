.report {
	display: grid;
	grid-template-columns: 370px 70px;
	gap: 5px;
	width: 470px;
	border: 2px solid #f3f3f3;
	border-radius: 10px;
	padding: 5px 10px;
	margin: 5px;

	button {
		margin: auto;
	}
}

.filters {
	.select {
		width: 20vw;
	}

    .checkbox {
        margin-bottom: 0px;
    }
}

.exportButton {
	margin-top: 15px;
}
