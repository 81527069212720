// This file will contain all varibales, our custom varibales and
//those from Ant Design which we want to override.
@import '~antd/lib/style/themes/default.less';

@primary-color: #32387e;
@secondary-color: #f40785;
@secondary-color-selected: #f759ad;
@select-item-selected-option-color: #353b80;
@processing-color: @primary-color;
@select-item-selected-bg: @background-color-base;
@skeleton-color: #f2f2f2;
@btn-primary-bg: @primary-color;

@label-color-fg: white;
@label-color-bg: #179bd5;
@label-color-border: @label-color-bg;

@layout-footer-color: @primary-color;
@table-header-filter-active-color: #f40785;

@menu-item-selected-bg: #f40785;
@menu-item-selected-border-color: #f40785;

@calendar-saturday-color: #096dd9;
@calendar-sunday-color: #d4040f;
@calendar-focus-color: black;

@badge-text-color: @component-background;
@sidebar-empty-text: black;
@danger-color: red;
@notification-bg: white;
@notification-fg: black;

@ant-back-top-content-bg: rgba(0, 0, 0, 0.7);
@ant-back-top-content-hover-bg: black;

@drop-down-list: black;
@ant-picker-cell-in-range: #bdc2c9;
@ant-picker-cell-range-start-end: #bdc2c9;
@ant-picker-cell-range-start-end-selected: #30428a;
@document-text: #979797;
@pagination-focus: #32387e;
@select-item-focus: fade(#32387e, 20%);
@button-primary-focus-bg: #384da1;
@button-primary-focus-color: #fff;
@dictionary-next-level-focus: white;
@dictionary-search-match: #30428a;
@text-selection-bg: #32387e;
@text-selection-fg: white;
@revert-icon-color: white;
@switch-focus: #169bd5;
@actions-table-column-bg: white;

@switch-bg: #bfbfbf;
@switch-circle-bg: white;
@switch-border: transparent;
@switch-bg-checked: #32387e;
@switch-box-focus: #32387e;

:root {
	--PC: @primary-color;
}
