@import '../../../styles/theme/index.less';
@import '../../../styles/theme/vars.less';

.pharmacyEdit {
	.menu {
		display: grid;
		justify-content: flex-start;
		align-content: center;
		align-items: center;
		grid-template-columns: 22vw 75px 10vw auto;
		gap: 15px;

		.status {
			height: 40px;
			padding: 5px 10px;
			color: white;
			border-radius: 15px;
			font-size: 18px;
			text-align: center;
			font-weight: 700;

			background-color: @primary-color;

			&.inContract {
				background-color: lightgreen;
			}

			&.duringApplication {
				background-color: orange;
			}

			&.outContract {
				background-color: red;
			}
		}

		h2 {
			margin: auto 0px auto 0px;
		}
	}

	.details {
		margin-top: 20px;

		.createButton {
			float: right;
			margin: 5px 0px 5px auto;
		}

		.description {
			.shadow-box();
			width: 55vw;
			padding: 15px;
			margin: 5px 0px;

			.ant-descriptions-item-label {
				margin: auto 0px;
			}

			.ant-row {
				height: 32px;
			}
		}
	}
}
