@import '../../../styles/theme/index.less';

@horizontalMargin: 2vw;

.menu {
	margin-left: @horizontalMargin;
}

.filters {
	margin-left: @horizontalMargin;
}

.tableContainer {
	.shadow-box;
	margin: 15px @horizontalMargin;
	padding: 0px 2px;

	.table {
		padding: 0px 2px;

		tr {
			.validationError {
				color: red;
			}

			.frozenRecord {
				opacity: 0.4;
			}

			&:hover {
				.frozenRecord {
					opacity: 1;
				}
			}
		}

		:global {
			.masked-table;
		}
	}
}