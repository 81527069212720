@import '../../../styles/theme/index.less';
.menu {
	margin-left: 2vw;
}

.tableContainer {
	.shadow-box;
	margin: 15px 2vw;
	padding: 0px 2px;

	.table {
		padding: 0px 2px;
		:global {
			.masked-table;
		}
	}
}