@import '../../../styles/theme/index.less';

.menu {
	margin-left: 2vw;

	.searchInput {
		width: 330px;
	}
}

.tableContainer {
	.shadow-box;
	margin: 15px 2vw;
	padding: 5px;

	.table {
		overflow-y: auto;
		padding: 0px 10px;
		max-height: 60vh;
		:global {
			.masked-table;
		}
	}
}