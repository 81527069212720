@import '../../../styles/theme/index.less';

.pharmacies {
	:global {
		.table-with-title {

			h2 {
				margin: auto 0px auto 2vw;
				.header-text();
			}

			.table-component {
				margin-left: 2vw;
				margin-right: 2vw;
			}
		}
	}

	.menu {
		display: grid;
		grid-template-columns: 2vw 480px;

		.searchBar {
			width: 250px;
			margin-right: 0px;
			margin-left: auto;
		}
	}

	.table {
		:global {
			.masked-table();
			.shadow-box();
		}

		.validation {
			 :global {
			.ant-space-item {
				margin-right: 2px !important;
			}
		 }
		}

		margin: 5px 0px;
	}
}
