@import 'vars';

@media (max-width: 767px) {
	/* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
	body {
		padding-top: 0;
	}
}

.bg-success {
	background-color: @success-color;
}

.bg-warning {
	background-color: @warning-color;
}

.bg-error {
	background-color: @error-color;
}

.ant-collapse-item + ant-collapse-item {
	padding-top: 10px;
}

.ant-form-item {
	margin-bottom: 0;
}

.ant-form-item-label {
	height: 28px;
}

.ant-calendar-picker {
	width: 100%;
}

.ant-time-picker {
	width: 100%;
}

.ant-input-number {
	width: 100%;
}

.hide {
	display: none;
}

.bottom-action-buttons > Button {
	margin-top: 10px;
	margin-bottom: 10px;
}

.bottom-action-buttons {
	text-align: center;
	margin-top: 10px;
}

.ant-collapse + button {
	margin-top: 10px;
}

.ant-table .ant-btn + .ant-btn {
	margin-left: 5px;
}

.ant-table-title {
	background-color: @primary-color;
	padding: 5px 10px 5px 10px;
	font-weight: 700;
	font-size: 1.3em;
	font-family: 'Chinese Quote';
	text-transform: uppercase;
}

.ant-collapse .ant-collapse-header {
	background-color: rgba(126, 193, 255, 0.07);
}

.ant-collapse + .ant-collapse {
	margin-top: 6px;
}

.row-alternate {
	background-color: rgba(126, 193, 255, 0.07);
}

.row-attention-grabbing-highlight > td {
	color: red;
}

.page-title {
	font-weight: 700;
	font-size: 1.2em;

	height: 45px;
	line-height: 45px;
	padding-left: 10px;
	padding-right: 10px;
	display: grid;
	grid-template-columns: 30vw auto 30vw;
	text-align: center;

	.left-content {
		display: flex;
		justify-content: flex-start;
	}

	.right-content {
		display: flex;
		justify-content: flex-end;
	}
}

.page-title-wide {
	grid-template-columns: 15vw auto 15vw;
}

.page-title-wide-right {
	grid-template-columns: auto 15vw;
}

.page-title > button {
	margin-left: 5px;
}

.page-title + div .ant-table {
	border-radius: 0;
}

.page-title {
	margin-bottom: 6px;
}

.page-title ~ *:not(.ant-table-wrapper) {
	padding-left: 6px;
	padding-right: 6px;
}

.page-title-addon {
	float: right;
	font-weight: 400;
}

.ant-table-pagination {
	padding-left: 14px;
	padding-right: 14px;
}

.ant-table-placeholder {
	border-radius: 4px;
}

.ant-layout-content {
	background-color: white;
	border-radius: 0;
	padding-bottom: 0;
	margin: 0;

	&--main {
		padding-top: 80px;
		padding-bottom: 8px;
		min-height: calc(100vh - 50px) !important;
	}
}

.ant-layout-footer {
	min-height: 50px;
	max-height: 50px;
	padding: 15px 50px !important;
	color: @layout-footer-color;
}

.ant-layout-header {
	padding: 0 25px;
	background-color: white !important;
}

.ant-descriptions-title {
	margin-bottom: 0;
}

.ant-descriptions .ant-descriptions-item-label,
.ant-descriptions .ant-descriptions-item-content {
	padding: 8px;
}

.ant-descriptions {
	margin-left: 2px;
	margin-right: 2px;
}

.ant-select-dropdown-menu-item {
	overflow: visible;
}

.ant-select-dropdown-menu {
	overflow-x: auto;
}

.ant-page-header {
	text-align: center;
	padding: 8px 24px;

	.ant-page-header-title-view {
		text-transform: uppercase;
		font-size: 20px;
	}

	.ant-page-header-title-view-title {
		color: @primary-color;
	}
}

.ant-card-body {
	padding: 6px;
}

.primary-card {
	margin-top: 16px;
	margin-bottom: 16px;
}

.ant-card-bordered {
	border-radius: 4px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
	margin-top: 0;
}

.ant-table-small > .ant-table-content > .ant-table-body {
	margin: 0;
}

.ant-table-content {
	overflow: initial !important;
}

.ant-modal-body .ant-table-content {
	overflow: auto !important;
	max-height: 75vh;
}

.ant-table-container {
	overflow-y: hidden;
	overflow-x: auto;
}

.ant-table-ping-right:not(.ant-table-has-fix-right)
	.ant-table-container::after {
	box-shadow: none;
}

.ant-table-small {
	font-size: 13px;
	font-weight: 500;
}

.mt-small {
	margin-top: 16px !important;
}

.mt-xs {
	margin-top: 8px !important;
}

.ml-xs {
	margin-left: 8px !important;
}

.m-xxs {
	margin: 4px !important;
}

.mb-xxs {
	margin-bottom: 4px !important;
}

.mb-xs {
	margin-bottom: 8px !important;
}

.mr-xs {
	margin-right: 8px !important;
}

.mr-xxs {
	margin-right: 4px !important;
}

.ml-xxs {
	margin-left: 4px !important;
}

.mh-xxs {
	margin: 0 4px;
}

.ph-xxs {
	margin: 0 4px;
}

.mt-none {
	margin-top: 0 !important;
}

.pr-xxs {
	margin-right: 4px !important;
}

.p-xxs {
	padding: 4px !important;
}

.m-none {
	margin: 0 !important;
}

.ant-picker-panel {
	.ant-picker-cell-inner {
		text-align: center;
	}
}

.d-none {
	display: none;
}

td.ant-table-cell {
	height: 45px;
}

.z-index-force {
	z-index: 9999 !important;
}

.filter-active {
	color: @table-header-filter-active-color !important;
}

.d-flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.tag-container--vertical {
	display: flex;
	flex-direction: column;
	& span:not(:last-child) {
		margin-bottom: 4px;
	}
}

.modal {
	&--search {
		width: 90% !important;
		max-width: 750px !important;
	}
}

.subject-reporting-date-picker .ant-picker-header .ant-picker-year-btn,
.subject-reporting-date-picker
	.ant-picker-header
	.ant-picker-header-super-next-btn,
.subject-reporting-date-picker
	.ant-picker-header
	.ant-picker-header-super-prev-btn {
	display: none;
}

.disabled-next-calendar .ant-picker-header .ant-picker-header-next-btn,
.disabled-next-calendar .ant-picker-cell-disabled {
	display: none;
}
.disabled-prev-calendar .ant-picker-header .ant-picker-header-prev-btn,
.disabled-prev-calendar .ant-picker-cell-disabled {
	display: none;
}

label.checkbox-customized.ant-checkbox-wrapper {
	line-height: inherit;
	text-align: center;
}

.text--error {
	color: @error-color !important;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

.pointer {
	cursor: pointer;
}

.text-sm {
	font-size: 0.8em;
}

.dictionary-textarea .ant-space-item:last-child {
	width: 100%;
}
.unobtrusive-element {
	& {
		opacity: 0.5;
	}

	&:hover {
		opacity: 1;
	}
}

.dimmed-element {
	opacity: 0.3 !important;
}

//hack - for random colors on some elements??
.ant-table-cell.ant-table-column-has-sorters:hover
	.ant-table-filter-trigger-container {
	background: transparent !important;
}

.ant-tag {
	background-color: @component-background;
}

.ant-select-selection-placeholder {
	opacity: 1 !important;
}

.ant-tag-has-color {
	color: @body-background;
	font-weight: bold;
}

.document {
	.category {
		border-bottom: 1px solid @primary-color;
	}

	span {
		color: @document-text;
	}
}

.text-right {
	text-align: right !important;
}

.popconfirm--accessible {
	.ant-popover-inner-content,
	.ant-popover-message,
	.ant-popover-message-title {
		padding: 0 !important;
	}

	.essity-popconfirm {
		padding: 12px 16px;
	}
}

.warning-color {
	color: @warning-color !important;
}

.user-manual-header {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-content: center;
	align-items: flex-start;
	line-height: 45px;
}

.z-index-up {
	z-index: 99 !important;
}

.text-white {
	color: @btn-primary-color !important;
}

.ant-picker-calendar-date-today {
	color: @component-background !important;
}

.menu-item--selected {
	color: @secondary-color !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
	color: @secondary-color !important;
}

.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.line-height-default {
	line-height: 1em;
}

.ant-tree-treenode-selected {
	.ant-tree-title {
		color: @component-background;
	}
	.ant-btn-circle {
		color: @component-background;
		border-color: @component-background;
		background-color: @primary-color;
	}
}

.ant-btn-dangerous.ant-btn-primary {
	color: @btn-primary-color;
	border: 1px fade(@danger-color, 30%);
	background-color: @danger-color;

	&:hover {
		color: @btn-primary-color;
		border: 3px @danger-color;
		background-color: @danger-color;
	}
}

.ant-notification-hook-holder,
.ant-notification-notice {
	background-color: @notification-bg;
}

.ant-notification-notice-message,
.ant-notification-notice-description {
	color: @notification-fg;
}

.bg-highlight {
	background-color: @dictionary-search-match !important;
	color: @notification-fg;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
	background: @ant-picker-cell-in-range;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background: @ant-picker-cell-range-start-end;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: @ant-picker-cell-range-start-end-selected;
}

td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
	box-shadow: 0 0 2px @calendar-focus-color;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
	background: @select-item-focus;
}

.table-header-checkbox {
	.container {
		position: absolute;
		top: 12px;
		right: -10px;
		.menu {
			background: transparent;
			border: none;
			cursor: pointer;

			&:focus {
				outline: none;
				border: none;
				//color: @secondary-color;
			}

			&:hover {
				color: @secondary-color;
			}
		}
	}
}

.visibility-hidden {
	visibility: hidden;
}

.notification-header {
	font-size: 16px;
	font-weight: 500;
	padding: 2px;

	&:focus {
		border: 2px solid black;
		border-radius: 4px;
	}
}

.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
	background-color: transparent;
}

button.ant-btn.ant-btn-ghost.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
	color: @primary-color;
}

::-moz-selection {
	/* Code for Firefox */
	color: @text-selection-fg;
	background: @text-selection-bg;
}

::selection {
	color: @text-selection-fg;
	background: @text-selection-bg;
}

.icon--revert-color:focus {
	color: @revert-icon-color !important;
}

.space-between {
	justify-content: space-between;
}

button.ant-switch {
	background-color: @switch-bg;
	border: 1px solid @switch-border;
	&.ant-switch-checked {
		background: @switch-bg-checked;
	}
	.ant-switch-handle::before {
		background-color: @switch-circle-bg;
	}
	&:focus {
		box-shadow: 0 0 3pt 3pt @switch-box-focus;
	}
}

.full-screen {
	width: 100% !important;
	min-height: 100vh !important;
}

.top-menu-bar .ant-menu-item.main-menu,
.top-menu-bar .ant-menu-submenu.main-menu {
	border: none !important;

	&:hover {
		border: none !important;
	}
}

.ant-divider-horizontal {
	margin: 6px 0;
}

.ant-table-row {
	cursor: pointer;
}

.shadow-box {
	box-shadow: 0px 0px 51px fade(#7f7f7f, 22%) !important;
	-webkit-box-shadow: 0px 0px 51px fade(#7f7f7f, 22%) !important;
	border-radius: 10px;
}

.masked-table {
	.ant-table-tbody > tr > td {
		border: none !important;
	}
}

.header-text {
	font-weight: 400;
	font-style: normal;
	font-size: 24px;
	margin-bottom: 0px;
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
	box-shadow: inset -4px 0 8px -8px @primary-color;
}

.fieldbox {
	display: grid;
	grid-template-columns: 200px auto;

	label {
		margin: auto 5px auto auto;
	}
}

.centered-view {
	margin: 0px 20vw 0px 20vw;
}

.status-icon {
	font-size: 20px;
}

.login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

.secondaryButton {
	background-color: @secondary-color;
	border-color: @secondary-color;

	color: white;

	&:hover {
		background-color: @secondary-color-selected;
		border-color: @secondary-color-selected;
		color: white;
	}

	&:disabled {
		background-color: fade(@secondary-color, 40%);
		border-color: fade(@secondary-color, 40%);
	}
}

div.ant-picker-month-panel {
	div.ant-picker-header {
		display: none !important;
	}
}

::-webkit-scrollbar {
	width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
	background: #f6f6f6; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
	background-color: fade(#32387e, 60%);//@primary-color; /* color of the scroll thumb */
	border-radius: 10px; /* roundness of the scroll thumb */
	border: 3px solid #f6f6f6; /* creates padding around scroll thumb */
}

.ant-row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
