@import '../../../styles/theme/vars.less';
@import '../../../styles/theme/index.less';

.orderLines {
	.details {
		margin-top: 20px;

		.warning {
			padding: 10px 0px 0px 5px;
			margin: 10px 25vw 10px 25vw;
			box-shadow: 0px 0px 31px fade(#d9001b, 60%) !important;
			-webkit-box-shadow: 0px 0px 31px fade(#d9001b, 60%) !important;
			border-radius: 10px;
			background-color: #d9001b;

			p {
				color: white;
				margin: 0px;
				padding: 0px;
				font-weight: bold;
				font-size: 15px;
			}
		}

		.info {
			padding: 10px 0px 0px 5px;
			margin: 10px 25vw 10px 25vw;
			box-shadow: 0px 0px 31px fade(#32387e, 60%) !important;
			-webkit-box-shadow: 0px 0px 31px fade(#32387e, 60%) !important;
			border-radius: 10px;
			background-color: #32387e;

			p {
				color: white;
				margin: 0px;
				padding: 0px;
				font-weight: bold;
				font-size: 15px;
			}
		}

		.section {
			padding: 10px 0px 0px 5px;
			margin: 10px 25vw 10px 25vw;

			.description {
				.shadow-box();

				&.products {
					padding-top: 0px;
					.ant-descriptions-header {
						margin-bottom: 0px;
					}

					:global {
						.masked-table();

						.ant-table-row {
							cursor: default;
						}

						.row-alternate,
						.ant-table-tbody > tr.ant-table-row:hover > td {
							background-color: white;
						}
					}
				}
			}

			.summary {
				height: 70px;
				background-color: @secondary-color;
				display: grid;
				grid-template-columns: 40% 20% 20% 20%;

				p {
					font-size: 16px;
					margin: auto 0px;
					color: white;
					font-family: 'Lato Bold', 'Lato Regular', 'Lato', sans-serif;
					text-align: center;

					&.prefix {
						font-weight: 700;
						font-style: normal;
						font-size: 24px;
						text-align: center;
					}

					&.value {
						font-weight: 700;
						font-style: normal;
						font-size: 16px;
					}
				}
			}
		}
	}

	.menu {
		display: grid;
		justify-content: flex-start;
		align-content: center;
		align-items: center;
		grid-template-columns: 25vw auto auto;

		.title {
			display: grid;
			grid-template-columns: auto auto auto;

			.realizationBox {
				height: 45px;

				p {
					font-weight: lighter;
					margin-top: -18px;
					margin-left: 10px;
				}
			}
		}

		h2 {
			margin: auto 10px;
			height: 45px;
		}
	}
}
