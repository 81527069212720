@import '../../../styles/theme/vars.less';

.ant-table-filter-trigger {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;

	.anticon {
		left: 80% !important;
	}
}

.ant-table-filter-trigger-container-open {
	background: transparent !important;
}

span.ant-table-filter-trigger-container:hover {
	background-color: transparent !important;
}